<template>
    <v-text-field
        class="input_text"
        :class="{ 'input-text--dark': dark }"
        outlined
        solo
        flat
        dense
        clearable
        :value="value"
        :label="label"
        :id="id"
        :hint="hint"
        persistent-hint
        hide-details
        v-bind="$attrs"
        v-on="$listeners"
        @input="$emit('input', $event)"
    >
        <template v-slot:prepend-inner>
            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                        {{ selectedCurrency.character_code }}
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item
                        class="py-0 my-0"
                        style="min-height: 30px"
                        v-for="(currency, index) in currencyItems"
                        :key="index"
                        @click="$emit('selectCurrency', currency)"
                    >
                        <v-list-item-title>{{ currency.character_code }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>
    </v-text-field>
</template>

<script>
export default {
    name: "PriceContainerInput",
    props: {

        selectedCurrency: {
            required: false,
            type: Object
        },
        currencyItems: {
            required: true,
            type: Array
        },
        value: {
            type: String,
            required: false,
            default: ''
        },
        label: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: true
        },
        hideLabel: {
            type: Boolean,
            default: false
        },
        hint: {
            type: String,
            required: false,
            default: ''
        },
        dark: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
    },
}
</script>

<style scoped>

</style>
