<template>
    <v-col cols="12" md="4">
        <div class="tab-btn">
            <div class="d-flex">
                <div class="mr-auto cursor-pointer_header menu_tab"  @click="showTab" :class="tab.show ? 'active' : ''">
                    {{ tab.name }}
                </div>
                <div>
                    <v-icon v-if="edit" color="primary" size="15" :title="$t('delete')" @click="deleteTab">
                        mdi-delete-outline
                    </v-icon>
                </div>
            </div>
        </div>
    </v-col>
</template>
<script>


import {mapGetters} from "vuex";


export default {
    name: 'FormTabDefault',
    props: {
        tab: Object,
        edit: {
            type: Boolean,
            default: false
        },
        entity_uuid: String
    },
    data() {
        return {
            loading: false,
            dialogUpdateTab: false,
        };
    },
    computed: {
        ...mapGetters(['listLanguageNames', 'lang']),
        languages() {
            return this.listLanguageNames
        },
    },
    methods: {
        showTab(){
            this.$emit('show-tab', this.tab);
        },
        async deleteTab() {
            if (this.edit === false) {
                return
            }
            if (confirm(this.$t('delete_tab'))) {
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()

                formData.append('entity_type', this.tab.entity_type)
                formData.append('sub_entity_id', this.tab.sub_entity_id)
                formData.append('sub_entity_type', this.tab.sub_entity_type)

                await this.$http
                    .post(`admin/from_tab_default/${this.tab.id}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('tab_has_been_deleted'))
                        this.$emit('delete-tab', this.tab);
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('tab_not_been_deleted'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })

            }
        },
    }
};
</script>


